import React, {Component} from 'react';
import './GoogleForm.css'

class GoogleForm extends Component {

    componentDidMount() {

    }

    render() {
        return (

    <iframe title="access-request-google-form" src="https://docs.google.com/forms/d/e/1FAIpQLScRLw-CxTg83Z3HtPlktr6jLs5S8N1G_m9v8Uus82Eet_869A/viewform?usp=sf_link"
        width='100%'
        height='100%'>

    </iframe>
        );
    }
}


export default GoogleForm;
