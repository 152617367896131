import React from 'react';
import { RedocStandalone } from 'redoc';
import './Redoc.css'


export default function Redoc(props) {

    return (
        <RedocStandalone
            specUrl={props.specUrl}
            options={{
                nativeScrollbars: false,
                hideDownloadButton: true,
                noAutoAuth: true,
                pathInMiddlePanel: false,
                // hideHostname: true,
                expandDefaultServerVariables: true,
                onlyRequiredInSamples: false,
                hideLoading: true,
                theme: {
                    colors: { primary: { main: '#313B45' } },
                    rightPanel: {
                        backgroundColor: '#313B45',
                        textColor: '#FFF',
                      },
                },
            }}
        />
    );
}
