import React, { Component } from 'react';
import ReactMarkdown from 'react-markdown';
import 'github-markdown-css/github-markdown-light.css';
import './MarkdownRenderer.css';



class MarkdownHeading extends Component {

    constructor(props) {
        super(props)
    }

    flatten = (text, child) => {
        return typeof child === 'string'
            ? text + child
            : React.Children.toArray(child.props.children).reduce(this.flatten, text)
    }

    render() {
        var children = React.Children.toArray(this.props.children)
        var text = children.reduce(this.flatten, '')
        var slug = text.toLowerCase().replace(/\W/g, '-')
        return React.createElement('h' + this.props.level, { id: slug }, this.props.children)
    }
}

class MarkdownRenderer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            markdown: "",
            toc: ""
        }
    }

    componentDidMount() {
        if (this.props.path) this.getFromFile(this.props.path)
    }

    getFromFile = async (URI) => {
        fetch(URI)
            .then((r) => r.text())
            .then(md => {
                this.setState({ markdown: md })
            })
    }

    render() {
        return (
            <div>
                <div className='markdown-body'>
                    {/* <div className="table-of-contents" dangerouslySetInnerHTML={{__html: this.state.toc}}></div> */}
                    <ReactMarkdown
                        source={this.state.toc}
                        // remarkPlugins={[remarkToc]}
                        // rehypePlugins={[link]}
                        children={this.state.markdown}
                        skipHtml={true}
                        sourcePos={false}
                        includeNodeIndex={true}
                        includeElementIndex={true}
                        // plugins={[remarkToc]}
                    // components={{heading: MarkdownHeading}}

                    />
                </div>
            </div>
        );
    }
}


export default MarkdownRenderer;
