import React from 'react'
import './App.css';
import Redoc from './components/Redoc';
import MarkdownRenderer from './components/MarkdownRenderer';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from "react-router-dom";
import GoogleForm from './components/GoogleForm';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<MarkdownRenderer path={'/README.md'}/>}/>
                    <Route path="/requestaccess" exact element={<GoogleForm />}/>
                    <Route path="/documentation" exact element={<Redoc specUrl={'endpoints.yaml'}/>}/>
                    {/*<GoogleForm />*/}
                    <Route element={<Navigate to="/"/>} />
                </Routes>
            </Router>
        </div>
    );
}

export default App;
